var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content-vertical-nav',{attrs:{"nav-menu-items":_vm.navMenuItems},scopedSlots:_vm._u([{key:"navbar",fn:function(ref){
var isVerticalNavMenuActive = ref.isVerticalNavMenuActive;
var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive;
return [_c('div',{staticClass:"navbar-content-container",class:{'expanded-search': _vm.shallShowFullSearch}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',{staticClass:"me-3",on:{"click":toggleVerticalNavMenuActive}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")]):_vm._e(),_c('app-bar-search',{attrs:{"shall-show-full-search":_vm.shallShowFullSearch,"data":_vm.appBarSearchData,"filter":_vm.searchFilterFunc,"search-query":_vm.appBarSearchQuery},on:{"update:shallShowFullSearch":[function($event){_vm.shallShowFullSearch=$event},function($event){return _vm.handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)}],"update:shall-show-full-search":function($event){_vm.shallShowFullSearch=$event},"update:searchQuery":function($event){_vm.appBarSearchQuery=$event},"update:search-query":function($event){_vm.appBarSearchQuery=$event}}}),(!_vm.shallShowFullSearch)?_c('div',{staticClass:"d-flex "},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.gotoDS()}}},[_c('v-img',{attrs:{"src":hover
                    ? require('@/assets/images/png/BuE DS Logo.png')
                    : require('@/assets/images/png/BuE DS Logo outline.png'),"max-height":"24px","max-width":"24px"}})],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-btn',{attrs:{"icon":"","title":_vm.$t('Help Desk')},on:{"click":function($event){return _vm.gotoHD()}}},[_c('v-img',{attrs:{"src":hover
                    ? require('@/assets/images/png/BuE HD Logo.png')
                    : require('@/assets/images/png/BuE HD Logo outline.png'),"max-height":"24","max-width":"24"}})],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-btn',{attrs:{"icon":"","title":_vm.$t('Knowledge Base')},on:{"click":function($event){return _vm.gotoKB()}}},[_c('v-img',{attrs:{"src":hover
                    ? require('@/assets/images/png/BuE KB Logo.png')
                    : require('@/assets/images/png/BuE KB Logo outline.png'),"max-height":"24","max-width":"24"}})],1)]}}],null,true)})],1):_vm._e()],1),_c('div',{staticClass:"d-flex align-center right-row"},[_c('app-bar-user-menu')],1)])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_c('a',{attrs:{"href":"https://bue-gmbh.de/","target":"_blank"}},[_vm._v("BuE")]),_vm._v(" + "),_c('a',{attrs:{"href":"https://www.evolvet.de/","target":"_blank"}},[_vm._v(" Evolvet ")])])])]},proxy:true}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }