const admin = [
  {
    path: '/dashboard',
    name: 'dashboard-crm',
    component: () => import('@/views/dashboards/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'read',
          subject: 'Public',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },

  //
  //* ——— User ——————————————————
  //

  {
    path: '/user/list',
    name: 'apps-user-list',
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'list_user',
          subject: 'User',
        },
        {
          action: 'manage_user',
          subject: 'User',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },
  {
    path: '/user/view/:id',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'list_user',
          subject: 'User',
        },
        {
          action: 'update_user',
          subject: 'User',
        },
        {
          action: 'manage_user',
          subject: 'User',
        },
        {
          action: 'delete_user',
          subject: 'User',
        },
        {
          action: 'update_user_email_address',
          subject: 'User',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },
  {
    path: '/profile/view/:id',
    name: 'apps-profile-view',
    component: () => import('@/views/apps/user/user-view/ProfileView.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'list_user',
          subject: 'User',
        },
        {
          action: 'update_user',
          subject: 'User',
        },
        {
          action: 'manage_user',
          subject: 'User',
        },
        {
          action: 'delete_user',
          subject: 'User',
        },
        {
          action: 'update_user_email_address',
          subject: 'User',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },

  //
  //* ——— Locations ——————————————————
  //

  {
    path: '/locations/list',
    name: 'apps-locations-list',
    component: () => import('@/views/apps/location/location-list/LocationList.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'read',
          subject: 'Public',
        },
      ],
    },
  },
  {
    path: '/locations/list-add',
    name: 'apps-locations-list-add',
    component: () => import('@/views/apps/location/location-list/AddLocationComponent.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'list_location',
          subject: 'Location',
        },
        {
          action: 'list_display',
          subject: 'Display',
        },
        {
          action: 'manage_location',
          subject: 'Location',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },
  {
    path: '/locations/list-display',
    name: 'apps-locations-display',
    component: () => import('@/views/apps/location/location-list/AddDisplayComponent.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'list_location',
          subject: 'Location',
        },
        {
          action: 'list_display',
          subject: 'Display',
        },
        {
          action: 'manage_location',
          subject: 'Location',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },
  {
    path: '/pages/account-settings',
    name: 'page-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },
  {
    path: '/pages/page/:id',
    name: 'page',
    component: () => import('@/views/pages/page/Page.vue'),
    meta: {
      layout: 'blank',
      resource: 'User',
      permissions: [
        {
          action: 'manage_page',
          subject: 'Page',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },

  /**
   * Other Pages
   */
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import('@/views/pages/Changelog.vue'),
    meta: {
      layout: 'content',
      permissions: [
        {
          action: 'read',
          subject: 'Public',
        },
      ],
    },
  },
  {
    path: '/install/:token/connect',
    name: 'connect',
    component: () => import('@/views/Connect.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
      permissions: [
        {
          action: 'manage_display',
          subject: 'Display',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },
  {
    path: '/install/:token/disconnect',
    name: 'disconnect',
    component: () => import('@/views/Disconnect.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
      permissions: [
        {
          action: 'manage_display',
          subject: 'Display',
        },
        {
          action: 'manage_system',
          subject: 'System',
        },
      ],
    },
  },
]

export default admin
