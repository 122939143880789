import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
  if (Array.isArray(route.meta.permissions)) {
    return route.meta.permissions.some(p => ability.can(p.action, p.subject))
  }

  return ability.can(route.meta.action || 'read', route.meta.resource)
})

export const _ = undefined
