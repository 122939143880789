// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // =============================
  baseURL: process.env.VUE_APP_BASE_URL,

  // timeout: 1000,
  headers: { Accept: 'application/json' },
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)
axiosIns.interceptors.response.use(
  response => response,
  error => {
    console.log(error, 'here')

    // Do something with response error
    if (error.response && error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/'
    }

    // if (status >= 400 && status <= 500) {
    //   ErrorService.handle({ status, statusText: message || statusText, data: errors || [] });
    // }
    return Promise.reject(error)
  },
)
Vue.prototype.$http = axiosIns

export default axiosIns
