import axios from '@axios'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    displays: [],
    locations: [],
    groups: [],
    users: [],
    roles: [],
    permissions: [],
    appsettings: [],
    location_hierarchy: [],
  },
  getters: {
    getAppsettingsValueByKey: state => key => {
      const item = state.appsettings.find(setting => setting.key === key)
      if (item) {
        return item.value
      }

      return null
    },
    getAppsettingsExists: state => key => {
      const item = state.appsettings.find(setting => setting.key === key)
      if (item) {
        return true
      }

      return false
    },

    getDisplaysNotConnected: state => state.displays.filter(d => d.token == null),
    getDisplaysByLocationId: state => locationId => state.displays.filter(d => d.location_id === locationId),
    getTopLocations: state => state.locations.filter(l => l.parent_id == null),
    getSublocationsByParentId: state => parentId => state.locations.filter(l => l.parent_id === parentId),
    getAppsettingsFormwerk: state => state.appsettings.filter(setting => setting.key.match(/^formwerk_/)),
    getAppsettingsGlobal: state => state.appsettings.filter(setting => setting.key.match(/^global_/)),

  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    setDisplays(state, displays) {
      state.displays = displays
    },
    setLocations(state, locations) {
      state.locations = locations
    },
    setLocationHierarchy(state, locationHierarchy) {
      state.location_hierarchy = locationHierarchy
    },
    setGroups(state, groups) {
      state.groups = groups
    },
    setRoles(state, roles) {
      state.roles = roles
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    setAppsettings(state, appsettings) {
      state.appsettings = appsettings
    },
    setUsers(state, users) {
      state.users = users
    },
  },
  actions: {
    fetchDisplays({ commit }) {
      return axios.get('/displays')
        .then(response => {
          commit('setDisplays', response.data.data)
        })
    },
    fetchLocations({ commit }) {
      return axios.get('/location')
        .then(response => {
          commit('setLocations', response.data.data)
        })
    },
    fetchLocationHierarchy({ commit }) {
      return axios.get('/locations/hierarchy')
        .then(response => {
          commit('setLocationHierarchy', response.data.data)
        })
    },
    fetchLocationHierarchyByDisplay({ commit }) {
      return axios.get('/displays/hierarchy')
        .then(response => {
          commit('setLocationHierarchy', response.data.data)
        })
    },
    fetchGroups({ commit }) {
      return axios.get('/groups')
        .then(response => {
          commit('setGroups', response.data.data)
        })
    },
    updateGroup({ dispatch }, { group }) {
      return new Promise((resolve, reject) => {
        axios.put(`/groups/${group.id}`, group).then(response => {
          // request succeeded
          dispatch('fetchGroups')
          resolve(response) // return response data to calling function
        }).catch(error => {
          // request failed
          reject(error) // return error to calling function
        })
      })
    },
    assignUsersToGroup({ dispatch }, { group, users }) {
      return new Promise((resolve, reject) => {
        axios.post(`/groups/${group.id}/assign`, { users }).then(response => {
          // request succeeded
          dispatch('fetchGroups')
          resolve(response) // return response data to calling function
        }).catch(error => {
          // request failed
          reject(error) // return error to calling function
        })
      })
    },
    storeGroup({ dispatch }, { group }) {
      return new Promise((resolve, reject) => {
        axios.post('/groups', group).then(response => {
          dispatch('fetchGroups')
          resolve(response)
        }).catch(error => {
          console.log('xue error')
          console.log(error)
          reject(error)
        })
      })
    },
    deleteGroup({ dispatch }, { group }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/groups/${group}`).then(response => {
          // request succeeded
          dispatch('fetchGroups')
          resolve(response) // return response data to calling function
        }).catch(error => {
          // request failed
          reject(error) // return error to calling function
        })
      })
    },
    fetchUsers({ commit }) {
      return axios.get('/users')
        .then(response => {
          commit('setUsers', response.data.data)
        })
    },
    fetchRoles({ commit }) {
      return axios.get('/roles')
        .then(response => {
          commit('setRoles', response.data.data)
        })
    },
    updateRole({ dispatch }, { role }) {
      return new Promise((resolve, reject) => {
        axios.put(`/roles/${role.id}`, role).then(response => {
          dispatch('fetchRoles')
          resolve(response)
        }).catch(error => {
          reject(error) // return error to calling function
        })
      })
    },
    storeRole({ dispatch }, { role }) {
      return new Promise((resolve, reject) => {
        axios.post('/roles', role).then(response => {
          dispatch('fetchRoles')
          resolve(response)
        }).catch(error => {
          console.log('xue error')
          console.log(error)
          reject(error)
        })
      })
    },
    deleteRole(ctx, { role }) {
      return axios.delete(`/roles/${role}`)
    },
    fetchPermissions({ commit }) {
      return axios.get('/permissions')
        .then(response => {
          commit('setPermissions', response.data.data)
        })
    },
    fetchAppsettings({ commit }) {
      return axios.get('/appsettings')
        .then(response => {
          commit('setAppsettings', response.data.data)
        })
    },
    updateAppsetting({ dispatch }, { appsetting }) {
      return new Promise((resolve, reject) => {
        axios.put(`/appsettings/${appsetting.key}`, appsetting).then(response => {
          dispatch('fetchAppsettings')
          resolve(response)
        }).catch(error => {
          reject(error) // return error to calling function
        })
      })
    },
    storeAppsetting({ dispatch }, { appsetting }) {
      return new Promise((resolve, reject) => {
        axios.post('/appsettings', appsetting).then(response => {
          dispatch('fetchAppsettings')
          resolve(response)
        }).catch(error => {
          console.log('xue error')
          console.log(error)
          reject(error)
        })
      })
    },
  },
}
