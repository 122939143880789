import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard-crm',
    key: 'dashboard',
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
      {
        action: 'list_display',
        subject: 'Display',
      },
      {
        action: 'manage_display',
        subject: 'Display',
      },
      {
        action: 'manage_location',
        subject: 'Location',
      },
      {
        action: 'list_location',
        subject: 'Location',
      },
    ],
  },
]
