import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import admin from './admin'
import publicRoutes from './public'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const loggedIn = userData && userData.id ? userData.id : null

      if (loggedIn) return { name: 'dashboard-crm' }

      // if (userRole === 'client') return { name: 'page-access-control' }

      return { name: 'auth-login', query: to.query }
    },
  },
  ...admin,
  ...publicRoutes,

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')

  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      sessionStorage.setItem('redirectPath', to.fullPath)

      return next({ name: 'auth-login', query: {} })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(sessionStorage.getItem('redirectPath') || '/')
  }

  return next()
})

export default router
