import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
const userPermissions = [
  'update_user',
  'update_user_email_address',
  'list_user',
  'manage_user',
  'delete_user',
  'manage_system',
  'list_location',
  'manage_location',
  'delete_location',
  'list_display',
  'manage_display',
  'list_page',
  'manage_page',
  'delete_page',
]
Vue.mixin({
  methods: {
    can: key => userPermissions.includes(key),
  },
})
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
Vue.use(VueCookies, { expire: '7d' })
