<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search>
          <div
            v-if="!shallShowFullSearch"
            class="d-flex "
          >
            <v-hover v-slot="{ hover }">
              <v-btn
                icon
                @click="gotoDS()"
              >
                <v-img
                  :src="
                    hover
                      ? require('@/assets/images/png/BuE DS Logo.png')
                      : require('@/assets/images/png/BuE DS Logo outline.png')"
                  max-height="24px"
                  max-width="24px"
                />
              </v-btn>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-btn
                icon
                :title="$t('Help Desk')"
                @click="gotoHD()"
              >
                <v-img
                  :src="
                    hover
                      ? require('@/assets/images/png/BuE HD Logo.png')
                      : require('@/assets/images/png/BuE HD Logo outline.png')"
                  max-height="24"
                  max-width="24"
                />
              </v-btn>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-btn
                icon
                :title="$t('Knowledge Base')"
                @click="gotoKB()"
              >
                <v-img
                  :src="
                    hover
                      ? require('@/assets/images/png/BuE KB Logo.png')
                      : require('@/assets/images/png/BuE KB Logo outline.png')
                  "
                  max-height="24"
                  max-width="24"
                />
              </v-btn>
            </v-hover>
          </div>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>
          <a
            href="https://bue-gmbh.de/"
            target="_blank"
          >BuE</a> +
          <a
            href="https://www.evolvet.de/"
            target="_blank"
          >
            Evolvet
          </a>
        </span>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
// eslint-disable no-restricted-syntax

import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'
import {
  mdiMapOutline, mdiAccountOutline, mdiMonitor, mdiLockOpenOutline, mdiSwapVertical, mdiCogOutline, mdiHomeOutline, mdiPlus, mdiMapMarker,
} from '@mdi/js'
import { i18n } from '@/plugins/i18n'

// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'

// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
// import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data
// import appBarSearchData from '@/assets/app-bar-search-data'

import { ref, watch } from '@vue/composition-api'
import axios from '@axios'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,

    // AppBarI18n,
    // AppBarThemeSwitcher,
    // AppBarNotification,
  },
  methods: {
    gotoHD() {
      window.open('https://bue.hd.handwerkskammer.cloud/')
    },
    gotoKB() {
      window.open('https://bue.kb.handwerkskammer.cloud/')
    },
    gotoDS() {},
  },
  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchData = ref([])
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      appBarSearchData.value = []
      if (appBarSearchQuery.value) {
        axios.get(`search/${appBarSearchQuery.value}`).then(response => {
          const { data } = response.data
          for (const item in data.Users) {
            appBarSearchData.value.push({ title: item, to: { path: `/user/view/${data.Users[item]}` }, icon: mdiAccountOutline })
          }
          for (const item in data.Locations) {
            appBarSearchData.value.push({ title: item, to: { name: 'apps-locations-list', params: { treeId: `L${data.Locations[item]}` } }, icon: mdiMapOutline })
          }
          for (const item in data.Displays) {
            appBarSearchData.value.push({ title: item, to: { name: 'apps-locations-list', params: { treeId: `D${data.Displays[item]}` } }, icon: mdiMonitor })
          }
          for (const item in data.Groups) {
            appBarSearchData.value.push({ title: item, to: { name: 'page-account-settings', params: { tab: 1, groupSelected: data.Groups[item] } }, icon: mdiLockOpenOutline })
          }

          // data.map(el =>[
          //   { title: el.name, to: { name: 'dashboard-crm' } },
          // ]);
        })
      }
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiMapOutline,
        mdiMonitor,
        mdiLockOpenOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
.v-application {
  .v-footer {
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
</style>
