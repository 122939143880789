import preset from '@/@core/preset/preset'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { en, de },
    current: 'de',
    defaultLocale: 'de',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
