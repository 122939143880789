export default {
  Dashboard: 'Dashboard',
  CRM: 'CRM',
  Analytics: 'Analytique',
  eCommerce: 'commerce électronique',
  Calendar: 'Calendrier',
  Chat: 'Discuter',
  Email: 'E-Mail',
  Invoice: 'Facturer',
  List: 'Lister',
  Preview: 'Aperçu',
  Edit: 'Bearbeiten',
  Add: 'Hinzufügen',
  User: 'Benutzer',
  Pages: 'Seiten',
  Authentication: 'Authentification',
  Integrations: 'Integrationen',
  General: 'Allgemein',
  Overview: 'Übersicht',
  Details: 'Einzelheiten',
  Suspended: 'Suspendiert',
  Theming: 'Thematisierung',
  Skin: 'Haut',
  Mode: 'Modus',
  Miscellaneous: 'Divers',
  Error: 'Erreur',
  Pricing: 'Prix',
  FAQ: 'FAQ',
  Typography: 'Typographie',
  Icons: 'Icônes',
  Gamification: 'Gamification',
  Cards: 'Cartes',
  Basic: 'De base',
  Statistics: 'Statistiques',
  Advance: 'Avance',
  Actions: 'Aktionen',
  Chart: 'Graphique',
  Components: 'Composants',
  Alert: 'Alerte',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Bouton',
  Menu: 'Menü',
  Dialog: 'Dialogue',
  Pagination: 'Seitenzahlen',
  Tabs: 'Onglets',
  Tooltip: 'Info-bulle',
  Chip: 'Ébrécher',
  Autocomplete: 'Saisie automatique',
  Checkbox: 'Case à cocher',
  Combobox: 'Boîte combo',
  Radio: 'Radio',
  Select: 'Sélectionner',
  Slider: 'Glissière',
  Switch: 'Changer',
  Textarea: 'Zone de texte',
  Textfield: 'Champ de texte',
  Rating: 'Notation',
  Tables: 'les tables',
  Charts: 'Graphiques',
  Datatable: 'Table de données',
  Documentation: 'Dokumentation',
  Apps: 'applications',
  Filters: 'Filter',
  Filter: 'Filtern ',
  Home: 'Start',
  Apply: 'Übernehmen',
  Save: 'Speichern',
  Delete: 'Löschen',
  Duplicate: 'Duplizieren',
  Created: 'Erstellt',
  Updated: 'Aktualisiert',
  Update: 'Aktualisieren',
  Submit: 'Einreichen',
  Remove: 'Entfernen',
  Users: 'Benutzer',
  Name: 'Name',
  Roles: 'Rollen',
  Role: 'Rolle',
  Password: 'Passwort',
  Permissions: 'Berechtigungen',
  Groups: 'Gruppen',
  Group: 'Gruppe',
  Slug: 'Slug',
  Saved: 'Gespeichert',
  Chartjs: 'Chartjs',
  Others: 'Autres',
  Snackbar: 'Snack-bar',
  Stepper: 'Pas à pas',
  Timeline: 'Chronologie',
  Locations: 'Standort',
  Display: 'Anzeige',
  Page: 'Seite',
  Active: 'Aktiv',
  Deactivated: 'Deaktiviert',
  Activate: 'Aktivieren',
  Deactivate: 'Deaktivieren',
  Mirror: 'Spiegeln',
  Start: 'Beginn',
  End: 'Ende',
  Relative: 'Relativ',
  Absolute: 'Absolut',
  Duration: 'Dauer',
  From: 'Von',
  Until: 'Bis',
  Bordered: 'Gerahmt',
  Create: 'Erstellen',
  Settings: 'Einstellungen',
  Search: 'Suche',
  Profile: 'Profil',
  global_standby_mode_timeframe: 'Zeitfenster',
  global_standby_mode_background_type: 'Hintergrundtyp',
  global_standby_mode_background_image: 'Hintergrundbild',
  global_standby_mode_background_file: 'Hintergrunddatei',
  global_standby_mode: 'Globaler Standby-Modus',
  formwerk_api_key: 'API-Key',
  formwerk_api_url: 'API-Url',
  formwerk_token_secret_key: 'API-Token-Secret-Key',
  Changelog: 'Changelog',
  Relativ: 'Relativ',
  Absolut: 'Absolut',
  configAbsolutDescription: 'Definiert einen festen Zeitrahmen, in dem die Standby-Seite sichtbar sein soll (Einstellungen hier überschreiben eine relative Zeitangabe). Die Sichtbarkeit kann in reinen Zeitfenstern (bspw. 10:00 - 18:00 Uhr), einem Datumsbereich (bspw. 01.01. - 15.01.) oder nach Wochentagen festgelegt werden. Kombinationen aus mehreren Angaben filtern die Sichtbarkeit weiter.',
  configRelativDescription: 'Definiert wie lange eine Seite angezeigt werden soll, bis die nächste Seite erscheint. Ist keine weitere Seite vorhanden, verbleibt diese Seite sichtbar.',
  Cancel: 'Abbrechen',
  Condition: 'Kondition',
  Weekday: 'Wochentage',
  Connect: 'Verbinden',
  Disconnect: 'Trennen',
  image: 'Bild',
  color: 'Farbe',
  Monday: 'Montag',
  Tuesday: 'Dienstag',
  Wednesday: 'Mittwoch',
  Thursday: 'Donnerstag',
  Friday: 'Freitag',
  Saturday: 'Samstag',
  Sunday: 'Sonntag',
  Close: 'Schliessen',
  Weekdays: 'Wochentage',
  Unmirror: 'Entspiegeln',
  Treeview: 'Arborescence',
  Security: 'Sicherheit',
  Logout: 'Ausloggen',
  'Total Users': 'Gesamtnutzer',
  'User List': "Liste d'utilisateur",
  'User View': 'Vue utilisateur',
  'APPS AND PAGES': 'APPLICATIONS ET PAGES',
  'Login V1': 'Connexion V1',
  'Login V2': 'Connexion V2',
  'Register V1': "S'inscrire V1",
  'Register V2': "S'inscrire V2",
  'Forgot Password?': 'Passwort vergessen?',
  'Remember Me': 'Angemeldet bleiben',
  'Enter your email and we will send you instructions to reset your password': 'Tragen Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts zu.',
  'Send reset link': 'Zurücksetzen-Link zusenden',
  'Back to login': 'Zurück zum Login',
  'Add Timeframe': 'Zeitrahmen hinzufügen',
  'Confirm password': 'Passwort bestätigen',
  'Under Maintenance': 'En maintenance',
  'Coming Soon': 'Bientôt disponible',
  'Not Authorized': 'Pas autorisé',
  'Knowledge Base': 'Base de connaissances',
  'Account Settings': 'Paramètres du compte',
  'USER INTERFACE': 'INTERFACE UTILISATEUR',
  'Expansion Panel': "Panneau d'extension",
  'FORMS & TABLES': 'FORMULAIRES & TABLEAUX',
  'Forms Elements': 'Éléments de formulaires',
  'File Input': 'Entrée de fichier',
  'Range Slider': 'Curseur de plage',
  'Date Picker': 'Sélecteur de date',
  'Month Picker': 'Sélecteur de mois',
  'Time Picker': 'Sélecteur de temps',
  'Form Layout': 'Disposition du formulaire',
  'Form validation': 'Validation du formulaire',
  'Simple Table': 'Tableau simple',
  'CHARTS AND MAPS': 'CARTES ET CARTES',
  'Apex Chart': 'Graphique Apex',
  'Leaflet Maps': 'Dépliant Cartes',
  'Menu Levels': 'Niveaux de menu',
  'Menu Level 2.1': 'Niveau de menu 2.1',
  'Menu Level 2.2': 'Niveau de menu 2.2',
  'Menu Level 3.1': 'Menu Niveau 3.1',
  'Menu Level 3.2': 'Niveau de menu 3.2',
  'Disabled Menu': 'Menu désactivé',
  'Raise Support': 'Augmenter le soutien',
  'Charts & Maps': 'Graphiques et cartes',
  'User Interface': 'Interface utilisateur',
  'Forms & Tables': 'Formulaires et tableaux',
  'Last edit': 'Zuletzt bearbeitet',
  'Configure Columns': 'Spalten konfigurieren',
  'Displayed records': 'Einträge',
  'Oh snap! Change a few things up and try submitting again.': 'Fehler sind aufgetreten',
  'All registered users': 'Registrierte Benutzer',
  'First Name': 'Vorname',
  'Last Name': 'Nachname',
  'Create user': 'Benutzer anlegen',
  'Profile Information': 'Basisinformationen',
  'Edit User Information': 'Benutzerinformationen bearbeiten',
  'Page Not Found': 'Seite nicht gefunden',
  'we could not find the page you are looking for': 'Wir konnten die gesuchte Seite nicht finden',
  'Back to home': 'Zurück zur Startseite',
  'Ensure your account is using a long, random password to stay secure.': 'Verwenden Sie ein langes, zufälliges Passwort, um sicher zu sein.',
  'A Role defines a set of tasks a user assigned the role is allowed to perform.': 'Eine Rolle definiert eine Reihe von Berechtigungen, die ein Benutzer, dem diese Rolle zugewiesen wurde, ausführen darf.',
  'Update Password': 'Passwort aktualisieren',
  'Current password': 'Aktuelles Passwort',
  'New password': 'Neues Passwort',
  'Confirm new password': 'Neues Passwort bestätigen',
  'Leave empty to keep current password': 'Leer lassen, um aktuelles Passwort zu behalten.',
  'Specify which roles this account should belong to': 'Geben Sie an, welche Rollen diesem Benutzer zugewiesen werden sollen',
  'Update your account\'s profile information and email address.': 'Basisinformationen Ihres Kontos',
  'My account': 'Mein Konto',
  'This is your password set at the moment.': 'Ihr aktuell verwendetes Passwort',
  'A good password is at least 15 characters or at least 8 characters long, including a number and a lowercase letter.': 'Verwenden Sie bitte mind. 8 Zeichen, Groß- und Kleinschreibung, sowie Zahlen und Sonderzeichen für Ihr neues Passwort.',
  'Role can not be deleted as it has users attached': 'Die Rolle kann nicht gelöscht werden, da ihr Benutzer zugeordnet sind.',
  'Specify which groups this account should belong to': 'Geben Sie an, welche Gruppen diesem Benutzer zugewiesen werden sollen.',
  'A group combines users into an organizational unit.': 'Eine Gruppe fasst Benutzer zu einer Organisationseinheit zusammen.',
  'Are you sure?': 'Sind Sie sicher?',
  'Once the account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.': 'Nach der Löschung des Kontos werden alle Ressourcen und Daten unwiderruflich gelöscht.',
  'Access rights': 'Zugriffsberechtigungen',
  'Manage Roles': 'Rollen verwalten',
  'A role is a collection of privileges (of possibly different services like the Users service, Moderator, and so on) that grants users with that role the ability to perform certain tasks or operations.': 'Eine Rolle ist eine Sammlung von Berechtigungen, die Benutzern mit dieser Rolle die Möglichkeit geben, bestimmte Aufgaben oder Operationen durchzuführen.',
  'Permission/Privilege': 'Berechtigungen',
  'A privilege is necessary to perform certain tasks and operations in an area.': 'Eine Berechtigung ist erforderlich, um bestimmte Aufgaben und Vorgänge durchzuführen zu dürfen.',
  'Role display name': 'Anzeigename der Rolle',
  'Role was saved': 'Rolle wurde gespeichert',
  'The role.slug has already been taken.': 'Das Slug für diese Rolle ist bereits vergeben.',
  'Defines a fixed time frame in which the standby mode should be active. Settings here override time specifications in page settings. Visibility can be set in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.': 'Definiert einen festen Zeitrahmen, in dem der Standby Modus aktiv sein soll. Einstellungen hier überschreiben Zeitangaben in Seiteneinstellungen. Die Sichtbarkeit kann in reinen Zeitfenstern (bspw. 10:00 - 18:00 Uhr), einem Datumsbereich (bspw. 01.01. - 15.01.) oder nach Wochentagen festgelegt werden. Kombinationen aus mehreren Angaben filtern die Sichtbarkeit weiter.',
  'Activate standby mode': 'Standbymodus aktivieren',
  'Standby mode': 'Standbymodus',
  'Design during standby mode': 'Darstellung während des Standbymodus',
  'Title of the location': 'Titel des Standorts',
  'Mirror display': 'Anzeige spiegeln',
  'Select the display whose content should be mirrored to the current display.': 'Wählen Sie die Anzeige aus, deren Inhalte auf die aktuelle Anzeige gespiegelt werden soll.',
  'This display mirrors contents of': 'Diese Anzeige spiegelt Inhalte von',
  'BuE appointments': 'BuE Termine',
  'Time relative visibility': 'Zeitbasierte Sichtbarkeit',
  'Set up automatic pagination': 'Automatische Paginierung einrichten',
  'Elements per page': 'Elemente pro Seite',
  'Page change after (Sec.)': 'Seitenwechsel nach (Sek.)',
  'Defines how long a page should be displayed until the next page appears. If no further page is available, this page remains visible.': 'Definiert wie lange eine Seite angezeigt werden soll, bis die nächste Seite erscheint. Ist keine weitere Seite vorhanden, verbleibt diese Seite sichtbar.',
  'Week days': 'Wochentage',
  'Defines a fixed time frame in which the page should be visible (settings here override a relative time specification). Visibility can be defined in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.': ' Definiert einen festen Zeitrahmen, in dem die Seite sichtbar sein soll (Einstellungen hier überschreiben eine relative Zeitangabe). Die Sichtbarkeit kann in reinen Zeitfenstern (bspw. 10:00 - 18:00 Uhr), einem Datumsbereich (bspw. 01.01. - 15.01.) oder nach Wochentagen festgelegt werden. Kombinationen aus mehreren Angaben filtern die Sichtbarkeit weiter.',
  'Page active?': 'Seite aktiv?',
  'The password does not match the minimal requirements. Please choose a new one': 'Das Passwort entspricht nicht den Mindestanforderungen. Bitte ein neues Passwort eintragen',
  'Dark mode': 'Dunkler Modus',
  'Default layout': 'Standard',
  'Visibility duration': 'Anzeigedauer',
  'Semi dark': 'Halbdunkel',
  'The picture max not exceed 3 MB and needs to be in PNG or JPG format)': 'Das Bild darf max. 3 MB groß sein und muss im PNG- oder JPG-Format vorliegen',
  'Add new user': 'Benutzer hinzufügen',
  'Active displays': 'Aktive Anzeigen',
  'Active Locations': 'Aktive Standorte',
  'Displays in standby': 'Anzeigen in Standby',
  'Mirrored displays': 'Gespiegelte Anzeigen',
  'Add Location': 'Standort hinzufügen',
  'Add Display': 'Anzeige erstellen',
  'Settings Saved': 'Einstellungen gespeichert',
  'Add New User': 'Anlegen',
  'All Locations': 'Alle Standorte',
  'Welcome to BuE DS!': 'BuE DS',
  'The value field is required.': 'Das Wertfeld ist erforderlich.',
  'successfully saved': 'erfolgreich gespeichert',
  'successfully updated': ' erfolgreich aktualisiert.',
  'Help Desk': 'Help Desk',
  'Change log': 'Change log',
  'Add Page': 'Seite hinzufügen',
  'more locations': 'weitere Locations',
  'Are you sure you want to delete this user ?': 'Soll dieser Benutzer wirklich gelöscht werden?',
  'Settings-Saved': 'Einstellungen gespeichert!',
  'Connect display': 'Anzeige verbinden',
  'Display successfully connected': 'Anzeige erfolgreich verbunden',
  'Display successfully disconnected': 'Anzeige erfolgreich getrennt',
  'Number of participants': 'Anzahl Mitglieder',
  'Edit Group': 'Gruppe bearbeiten',
  'Add Group': 'Neue Gruppe anlegen',
  'Are you sure you want to delete this group ?': 'Wollen Sie diese Gruppe wirklich löschen ?',
  'Are you sure you want to delete this page ?': 'Möchten Sie diese Seite wirklich löschen?',
  'Are you sure you want to delete this display ?': 'Möchten Sie diese Anzeige wirklich löschen?',
  'Roles and Permissions': 'Rollen und Berechtigungen',
  'Are you sure you want to delete this role ?': 'Wollen Sie diese Rolle wirklich löschen ?',
  'Are you sure you want to delete this location and all sublocations?': 'Sollen die Standorte und alle Unterstandorte wirklich gelöscht werden?',
  'This Role can not be deleted because it has assigned users': 'Diese Rolle kann nicht gelöscht werden, da sie Benutzern zugeordnet ist.',
  'Standby mode background type': 'Standby-Modus Hintergrundtyp',
  'Upload Backgroundimage': 'Hintergrundbild hochladen',
  'Current Backgroundimage': 'Aktuelles Hintergrundbild',
  'Start Date': 'Start Datum',
  'End Date': 'Ende Datum',
  'Start Time': 'Start Zeit',
  'End Time': 'Ende Zeit',
  'Edit Role': 'Rolle bearbeiten',
  'Add Role': 'Neue Rolle anlegen',
  'Page active': 'Seite aktiv',
  'This field is required': 'Dies ist ein Pflichtfeld',
  'Title of the display': 'Titel der Anzeige',
  'Title of the page': 'Titel der Seite',
  'The email must be a valid email address.': 'Die E-Mail-Adresse scheint ungültig zu sein.',
  'No time set': 'Keine Zeit eingestellt',
  'The role was deleted successfully': 'Rolle erfolgreich gelöscht',
  'Select Display to Mirror': 'Anzeige zum Spiegeln auswählen',
  Description: 'Beschreibung',
  Required: 'Erforderlich',
  "Copy isn't supported on this device/browser": 'Kopieren wird in diesem Browser nicht unterstützt',
  'Code Copied Successfully': 'Code erfolgreich kopiert',
  'Search Displays': 'Anzeigen suchen',
  'Duration (seconds)': 'Dauer (Sek.)',
  Config: 'Konfiguration',
  Disconnects: 'Trennen',
}
