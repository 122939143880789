import {
  mdiAccount, mdiCog, mdiLifebuoy, mdiPlus, mdiSwapVertical, mdiMapMarkerMultiple,
} from '@mdi/js'

export default [
  {
    subheader: 'Locations',
    key: 'subheader-locations',
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
      {
        action: 'manage_location',
        subject: 'Location',
      },
      {
        action: 'list_location',
        subject: 'Location',
      },
      {
        action: 'delete_location',
        subject: 'Location',
      },
      {
        action: 'manage_display',
        subject: 'Display',
      },
    ],
  },
  {
    title: 'All Locations',
    to: 'apps-locations-list',
    icon: mdiMapMarkerMultiple,
    key: 'all-locations',
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
      {
        action: 'manage_location',
        subject: 'Location',
      },
      {
        action: 'list_location',
        subject: 'Location',
      },
      {
        action: 'delete_location',
        subject: 'Location',
      },
      {
        action: 'read',
        subject: 'Public',
      }
    ],
  },
  {
    title: 'Add Location',
    to: { name: 'apps-locations-list-add' },
    icon: mdiPlus,
    key: 'add-location',
    force: true,
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
      {
        action: 'manage_location',
        subject: 'Location',
      },
    ],
  },
  {
    title: 'Add Display',
    to: { name: 'apps-locations-display' },
    icon: mdiPlus,
    key: 'add-display',
    force: true,
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
      {
        action: 'manage_display',
        subject: 'Display',
      },
    ],
  },
  {
    subheader: 'Settings',
    key: 'subheader-settings',
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
    ],
  },
  {
    title: 'Users',
    to: 'apps-user-list',
    icon: mdiAccount,
    key: 'user-list',
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
      {
        action: 'manage_user',
        subject: 'User',
      },
      {
        action: 'list_user',
        subject: 'User',
      },
    ],
  },
  {
    title: 'Settings',
    to: { name: 'page-account-settings', params: { id: 21 } },
    icon: mdiCog,
    key: 'settings',
    permissions: [
      {
        action: 'manage_system',
        subject: 'System',
      },
    ],
  },
  {
    subheader: 'Knowledge',
    key: 'subheader-knowledge',
    permissions: [
      {
        action: 'read',
        subject: 'Public',
      },
    ],
  },
  {
    title: 'Help Desk',
    href: 'https://bue.hd.handwerkskammer.cloud',
    target: '_blank',
    icon: mdiLifebuoy,
    key: 'help-desk',
    permissions: [
      {
        action: 'read',
        subject: 'Public',
      },
    ],
  },
  {
    title: 'Changelog',
    to: { name: 'changelog' },
    icon: mdiSwapVertical,
    key: 'change-log',
    permissions: [
      {
        action: 'read',
        subject: 'Public',
      },
    ],
  },
]
