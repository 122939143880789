import { mdiAccountOutline, mdiSwapVertical, mdiCogOutline, mdiHomeOutline, mdiPlus, mdiMapMarker } from '@mdi/js'
import { i18n } from '@/plugins/i18n'

/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Seiten' },


  // dashboard
  { title: i18n.tc('Dashboard'), to: { name: 'dashboard-crm' }, icon: mdiHomeOutline },
  { title: i18n.tc('Changelog'), to: { name: 'changelog' }, icon: mdiSwapVertical },
  { title: i18n.tc('All Locations'), to: { name: 'apps-locations-list' }, icon: mdiMapMarker },
  { title: i18n.tc('Add Location'), to: { name: 'apps-locations-list-add' }, icon: mdiPlus },
  { title: i18n.tc('Add Display'), to: { name: 'apps-locations-display' }, icon: mdiPlus },
  { title: i18n.tc('User'), to: { name: 'apps-user-list' }, icon: mdiAccountOutline },
  { title: i18n.tc('Settings'), to: { name: 'page-account-settings' }, icon: mdiCogOutline },

  { header: 'Locations' },

]